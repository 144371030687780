/* Reset default settings */

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.socilaMediaIcontilt {
	transition: transform 0.2s ease;
}

.socilaMediaIcontilt:hover {
	transform: rotate(-6deg); /* Adjust the angle as needed */
}
